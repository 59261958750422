/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import "~swiper/scss/navigation";
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';

/* Global */
@font-face {
    font-family: 'Agfa';
    src: url('assets/fonts/AgfaRotisSansSerif.woff2') format('woff2'),
        url('assets/fonts/AgfaRotisSansSerif.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.transparent {
    opacity: 0 !important;
}

.icon {
    width: 32px;
    height: 32px;
    margin: 0 auto;
    display: inline-block;
}

ion-toolbar {
    --background: transparent;
    --ion-color-base: transparent !important;
    --border-style: none;
}

ion-toolbar.black {
    --background: black;
    --ion-color-base: black !important;
}

.swiper {
    padding-bottom: 40px;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0;
}

.children-maps .swiper {
    padding-bottom: 0px;
}

.swiper-pagination {
    left: 50% !important;
    width: 50% !important;
    margin-left: -25%;
}

.swiper-button-prev, .swiper-button-next {
    position: absolute;
    top: 50%;
    width: auto;
    height: auto;
    padding: 5px;
    margin-top: calc(0px - var(--swiper-navigation-size) / 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 0px;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: 0px;
    right: auto;
}

.box {
    background-color: white;
    padding: 25px;
    border-radius: 15px;
    text-align: left;
    .box-header {
        font-size: 32px;
        position: relative;
        padding-left: 42px;
        line-height: 32px;
        margin-bottom: 15px;
        &.box-header-no-icon {
            padding-left: 0px;
        }
        .icon {
            position: absolute;
            left: 0px;
            top: 0px;
        }
        .box-header-subtitle {
            font-size: 18px;
        }
        &.box-header-split {
            line-height: 24px;
            .icon {
                top: 5px;
            }
        }
    }
    .box-buttons {
        margin-top: 15px;
        overflow: hidden;
        .box-button {
            width: 50%;
            float: left;
            text-align: center;
            .box-button-icon {
                margin-bottom: 5px;
            }
        }
    }
}

.container-intro {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.map-container {
    text-align: center;
    .map {
        max-height: 300px;
    }
}

.map-element-label {
    position: absolute;
    left: 0px;
    top: 0px;
    color: white;
    background-color: var(--ion-color-primary);
    padding: 5px 10px;
}

.button {
    height: 56px;
    line-height: 56px;
    display: block;
    text-align: center;
    font-size: 32px;
    &.button-black {
        background-color: black;
        color: white;
    }
    &.button-primary {
        background-color: var(--ion-color-primary);
        color: white;
    }
    &.button-small {
        height: 40px;
        line-height: 40px;
        font-size: 27px;
    }
}

#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  &.padding-small {
    padding-left: 15px;
    padding-right: 15px;
  }
  &.padding-large {
    padding-left: 30px;
    padding-right: 30px;
  }
}

#container-dynamic {
  &.padding-small {
    padding: 15px;
  }
  &.padding-large {
    padding: 30px;
  }
}


ion-content {
    --ion-background-color: var(--ion-color-primary);
    font-size: 18px;
}

ion-content.bg-white {
    --ion-background-color: white;
}

#container-list {
  min-height: 100%;
  left: 0;
  right: 0;
  padding-top: 15px;
  &.padding-small {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#container-location {
  height: 100%;
}

.location-element-children {
    border: 1px solid rgb(200,200,200);
    margin-top: 15px;
    position: relative;
    z-index: 2000;
}

/* Header */
.vh-header {
    height: 80px;
    padding: 15px;
    position: relative;
    width: 100%;
    z-index: 100;
    &.pink {
        background-color: var(--ion-color-primary);
    }
    .vh-logo {
        position: relative;
        z-index: 10;
    }
}

.plt-ios {
    .vh-header {
        margin-top: var(--ion-safe-area-top);
    }
}

.vh-header-title {
    height: 80px;
    line-height: 80px;
    color: white;
    position: absolute;
    left: 0px;
    top: 0px;
    padding-left: 80px;
    font-size: 32px;
    &.vh-header-title-split {
        padding-top: 15px;
        line-height:18px;
        .vh-header-title-main {
            font-size: 24px;
        }
        .vh-header-title-sub {
            font-size: 16px;
        }
    }
}

.children-header-title {
    height: 80px;
    line-height: 80px;
    color: black;
    position: absolute;
    left: 90px;
    top: 7px;
    padding-left: 0px;
    font-size: 32px;
    z-index: 100;
    &.children-header-title-split {
        padding-top: 15px;
        line-height:18px;
        .children-header-title-main {
            font-size: 24px;
        }
        .children-header-title-sub {
            font-size: 16px;
        }
    }
}

.vh-header-button {
    width: 80px;
    height: 80px;
    position: absolute;
    right: 0px;
    top: 0px;
    display: table;  
    z-index: 11;      
    .icon {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
}

/* Footer */
.vh-footer {
    height: 80px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
    background-color: white;
}

.vh-footer-padding {
    padding: 15px;
}

.icon-footer {
    width: 22px;
    height: 22px;
    margin-left: 15px;
    color: white;
}

.icon-footer-text {
    color: white;
    padding-left: 10px;
    padding-right: 10px;
}

.icon-footer-end {
    width: 22px;
    height: 22px;
    margin-right: 15px;
}

/* Backgrounds */
.bg-image {
    height: 100%;
    background-size: cover;
    background-position: center center;    
}

.bg-color {
    background-color: #FFA0AB;
}

.bg-image-0 {
    background-image: url('assets/bg0.jpg');
}

.bg-image-1 {
    background-image: url('assets/bg1.jpg');
}

.bg-image-2 {
    background-image: url('assets/bg2.jpg');
}

.bg-image-3 {
    background-image: url('assets/bg3.jpg');
}

/* Navigation */
.navigation {
    padding: 40px;
    .navgation-item {
        border-bottom: 1px solid white;
        font-size: 32px;
        line-height: 40px;
        color: white;
        text-align: left;
        padding-top: 20px;
        position: relative;
        .icon {
            position: absolute;
            right: 0px;
        }
    }
}

.video-container {
    position: relative;
    height: 100%;
    background-color: black;
    .video-overlay {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: rgba(0,0,0,0.7);
        color: white;
        font-size: 18px;
        padding: 15px;
    }
}

/* Locations */
.hidden-iframe {
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
    overflow: hidden;
}

.frame-container {
    width: 100%;
    height: 100%;   
    overflow: hidden;
}

/* Content Overlay */
.content-overlay-show {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 2000;
    .icon {
        width: 44px;
        height: 30px;
    }
}
.location-title {
    font-size: 32px;
    position: relative;
}

.location-link {
    cursor: pointer;
}

.location-header {
    img {
        width: 100%;
        height: auto;
    }
}

.location-element-preview {
    img {
        width: 100%;
        height: auto;
    }
}

.location-element-title {
    text-align: center;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.location-excerpt {
    font-weight: bold;
}

.input-email {
    background-color: white;
    border-radius: 0px;
    line-height: 41px;
    height: 41px;
    display: block;
    width: 100%;
    border: none;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
}
.content-overlay {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: white;
    .content-overlay-entry {
        height: 70px;
        padding: 15px;
        padding-left: 70px;
        position: relative;
        font-size: 24px;
        line-height: 24px;
        &:nth-child(even) {
            background-color: #F4F4F4;
        }
        small {
            display: block;
            font-size: 18px;
            line-height: 20px;
        }
        .content-overlay-icon {
            position: absolute;
            left: 0px;
            top: 0px;
            height: 70px;
            width: 70px;
            text-align: center;
            .icon {
                margin-top: 19px;
                margin-left: 19px;
                margin-right: 19px;
                width: 32px;
                height: 32px;
            }
        }
    }
    .content-overlay-hide {
        position: absolute;
        bottom: 100%;
        right: 15px;
        margin-bottom: 5px;
        z-index: 2000;
        .icon {
            width: 44px;
            height: 30px;
        }
    }
}

/* Location */
#location {
    background-color: white;
}

.location-header {
    margin-bottom: 15px;
}

.location-title {
    color: black;
    line-height: 30px;
    margin-bottom: 10px;
}

.location-subtitle {
    font-size: 18px;
}

.location-excerpt {
    margin-bottom: 15px;
}

.location-links {
    margin-bottom: 15px;
    margin-left: -10px;
    margin-right: -10px;
}

.location-content {
    padding: 15px;
}

.location-text {
    margin-bottom: 15px;
}

.location-element-text-after {
    margin-bottom: 15px;
}

.location-link {
    height: 70px;
    line-height: 70px;
    font-size: 24px;
    color: white;
    text-align: center;
    background-color: var(--ion-color-primary);
}

.location-element-preview {
    cursor: pointer;
    position: relative;
    img {
        display: block;
    }
   .location-element-indicator {
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        img {
            margin-top: 4px;
        }
        background-color: var(--ion-color-primary);
        .icon {
            ion-icon {
                width: 32px;
                height: 32px;
                color: white;
                margin-top: 4px;
            }
        }
   } 
}

.audio-flag {
    position: fixed;
    right: 0px;
    top: 100px;
    width: 62px;
    height: 47px;
    line-height: 47px;
    color: white;
    z-index: 100;
    .icon {
        margin-left: 15px;
        margin-top: 7px;
    }
    ion-icon {
        width: 32px;
        height: 32px;
    }
    padding-right: 15px;
    background-color: black;
}

/** Children Tour **/

.background-children {
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    // font-size: 16px;
    font-size: 17px;
}

.background-events {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.children-overlay-gradient {
    height: 100%;
    overflow: scroll;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 20%, rgba(255,255,255,0.9) 80%, rgba(255,255,255,0) 100%);
}

.children-logo {
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: 3000;
}

.children-maps {
    padding-left: 20%;
    padding-right: 20%;
    max-height: 100%;
    height: 100%;
}

.children-map {
    height: 100%;
    text-align: center;
    img {
        max-height: 100%;
    }
}

.children-station-title {
    width: 80px;
    height: 31px;
    text-align: center;
    line-height: 31px;
    color: white;
    background-color: var(--ion-color-primary);
    position: absolute;
    right: 10px;
    bottom: 190px;
}

.children-scan {
    position: absolute;
    right: 10px;
    bottom: 100px;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 20px;
    background-color: black;
    color: white;
    padding: 10px;
    padding-top: 13px;
    font-size: 16px;
    .icon {
        width: 32px;
    }   
}

.children-list {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 20px;
    background-color: black;
    color: white;
    padding: 10px;
    padding-top: 13px;
    font-size: 16px;
    .icon {
        width: 32px;
    }   
}

.children-narrative {
    height: 100%;
}

.narrative-page {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20%;
    padding-right: 20%;    
    overflow: auto;
}

.narrative-page-start {
    position: relative;
    overflow: hidden;
}

.narrative-page-content {
    display: none;
    position: relative;
}

.narrative-title-outer-container {
    display: table;
    height: 100%;
    text-align: center;
    width: 100%;
    .narrative-title-container {
        display: table-cell;
        vertical-align: middle;
    }
}

.narrative-title {
    font-size: 27px;
}

.narrative-subtitle {
    font-size: 18px;
}

.narrative-entry {
    margin-bottom: 15px;
}

.narrative-page {
    .narrative-page-container {
        display: table;
        width: 100%;
        .narrative {
            display: table-cell;
            vertical-align: top;
        }
        .narrative-entry {
            display: none;
        }
    }
}

.button-end-station {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 100px;
    .children-button {
        width: 220px;
        margin: 30px auto;
    }
}

.children-button-center {
    .children-button {
        display: inline-block;
        padding: 0px 15px;
    }
}

.children-dialog {
    padding: 15px 40px;
    bottom: 0px;
    left: 0px;
    width: 100%;    
    position: absolute;
    opacity: 0;
}

.children-feedback {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 40px;
    z-index: 2000;
    display: none;
}

.children-help {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 100;
}

.close-help {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: var(--ion-color-primary);
    color: white;
    line-height: 31px;
    height: 31px;
    width: 31px;
    text-align: center;
    font-size: 21px;
    ion-icon {
        margin-top: 4px;
    }
}

#game .help {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    z-index: 100;
    display: none;
    &.active {
        display: block;
    }
    .help-instruction {
        padding-left: 60px;
        padding-right: 60px;
    }
}

.children-feedback-text {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    position: relative;
    z-index: 2000;
    display: none;
    .game-element-button {
        position: absolute;
        background-color: black;
        right: -10px;
        bottom: -10px;
        color: white;
        height: 32px;
        line-height: 32px;
    }
}

.dialog-box {
    background-color: white;
    height: 80px;
    position: relative;
    display: none;
    border-radius: 5px;
    padding: 15px;
    padding-top: 12px;
}

.dialog-image-container {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 5%;
    display: none;
    img {
        display: block;
        margin: 0 auto;
        border: 5px solid white;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
    }
    .dialog-image-description {
        background-color: white;
        padding-left: 5px;
        padding-bottom: 5px;
        padding-right: 5px;
    } 
}

.dialog-name {
    position: absolute;
    left: 40px;
    top: -5px;
    color: white;
    width: 100px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    z-index: 5;
}

.children-actors {
    > img {
        position: fixed;
        bottom: 60px;
        transition: all 2s;
        transform-origin: center;
    }
    #snow {
        left: -500px;
        width: 150px;
    }
    #hektor {
        left: 60%;
        width: 300px;
        opacity: 0;
    }
}

#children-button-center {
    display: none;
    position: fixed;
    top: 50%;
    width: 100%;
    text-align: center;
    margin-top: -20px;
    height: 31px;
    line-height: 31px;
}

#children-quiz {
    display: none;
    position: fixed;
    top: 20%;
    width: 100%;
    text-align: center;
    margin-top: -20px;
    z-index: 2000;
}

#children-quiz .answer {
    display: inline-block;
    min-width: 130px;
    margin-bottom: 15px;
    padding: 5px 15px;
    background-color: black;
    color: white; 
    border: 3px solid black;
    &.correct {
        border: 3px solid green;
    }
    &.wrong {
        border: 3px solid red;
    }
}

#children-quiz.children-quiz-columns {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 180px;
    padding-left: 150px;
    padding-right: 150px;
    .answer {
        margin: 0px 15px;
    }
}

#children-quiz.children-quiz-sentences {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 180px;
    padding-left: 150px;
    padding-right: 150px;
    .answer {
        margin: 0px 15px;
    }
}

#touch-icon {
    text-align: center;
    position: absolute;
    bottom: -80px;
    left: 50%;
    margin-left: -50px;
    width: 100px;
}

.narrative-image {
    margin-top: 30px;
    margin-bottom: 30px;
    img {
        display: block;
        margin: 0 auto;
        border: 5px solid white;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
    }
    .narrative-image-subtitle {
        display: block;
        text-align: center;
        margin-top: -5px;
    }
}

.children-content {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 300px;
    text-align: right;
    z-index: 2000;
}

.children-content-entry {
    padding: 5px;
    background-color: var(--ion-color-primary);
    border-radius: 5px;
    height: 42px;
    width: 42px;
    display: inline-block;
    margin-left: 5px;
    z-index: 2000;
    display: none;
    .icon {
        height: 32px;
        ion-icon {
            width: 32px;
            height: 32px;
            color: white; 
        }        
    }
}

.children-next, .game-09-check-results {
    position: absolute;
    right: 0px;
    bottom: 0px;
    background-color: black;
    font-size: 22px;
    //height: 31px;
    height: 40px;
    //line-height: 31px;
    line-height: 40px;
    width: 50px;
    text-align: center;
    z-index: 2000;
    padding: auto !important;
    .icon {
        margin-top: 4px;
        height: 22px;
        width: 22px;        
        img {
            height: 22px;
            width: 22px;
        }
    }
    &.game-09-check-results, &.inactive {
        opacity: 0.6;
    }
    &.active {
        opacity: 1;
    }    
}

.children-button, .children-button-style {
    height: 42px;
    line-height: 42px;
    // height: 31px;
    // line-height: 31px;
    background-color: black;
    color: white;
    text-align: center;
    &.previous-button, &.next-button {
        width: 50px;
        // width: 40px;
        position: fixed;
        bottom: 0px;
        .icon {
            width: 22px;
            height: 22px;
            margin-top: 4px;
        }
    }
    &.previous-button {
        left: 0px;
    }
    &.next-button {
        right: 0px;
    }
    &.skip-button {
        position: fixed;
        left: 0px;   
        bottom: 0px;
        font-size: 17px;
        padding: 0px 10px;
    }
    .icon {
        margin-top: 3px;
        height: 22px;
        width: 22px;        
        img {
            height: 22px;
            width: 22px;
        }
    }    
}

.end-game {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

#game {
    font-size: 17px;
}

.additional-text {
    margin-bottom: 10px;
}

/******************
 * Games
 * ****************/
.template {
    display: none;
}

#game {
    padding-left: 20px;
    padding-right: 20px;
    .game-element {
        margin-left: 80px;
        margin-right: 80px;
    }
}

.game-feedback-key {
    margin-top: 60px;
    img {
        // margin-bottom: 15px;
    }
}

.game-element {
    position: relative;
    background-color: white;
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px;
    text-align: center;
    input {
        background-color: transparent;
        width: 100%;
        -webkit-appearance: none;
        border: none;
        box-shadow: none;
        outline: none;
        border-bottom: 1px solid rgb(200,200,200);
        line-height: 34px;
        text-align: center;
    }
    .game-element-title {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .game-element-button {
        position: fixed;
        bottom: 0px;
        right: 0px;
    }
}

.game-element-show-content {
    position: absolute;
    right: -15px;
    top: 15px;
    width: 42px;
    height: 42px;
    border-radius: 5px;
    background-color: #FFA0AB;
    padding-left: 8px;
    padding-top: 8px;
    .icon {
        width: 24px;
        height: 24px;
    }    
}

.game-element-button {
    padding: 0 15px;
    &.children-next {
        padding: 0px;
    }
}

.game-page-counter {
    position: absolute;
    right: 30px;
    top: 30px;
    border-radius: 20px;
    background-color: white;
    padding: 10px 15px;
    z-index: 500;
}

.game-feedback-key {
    .game-element-button {
        margin: 0 auto;
    }
}
.game-key-container {
    text-align: center;
    margin: 30px auto;
}

.key-container-11 {
    z-index: 1000;
    position: relative;
}

.game-element-text {
    text-align: left;
    padding: 30px;
}

.game-introduction, .game-main, .game-feedback, .game-introduction-element, .game-feedback-element, .game-main-element, .game-element-inner-feedback {
    display: none;
}

.game-quote .game-element {
    margin-top: 30px;
    position: relative;
    .game-main-buttons {
        position: absolute;
        width: 100%;
        left: 0px;
        bottom: -50px;
        .game-main-button {
            float: left;
            width: 45%;
            margin-right: 10%;
            &:nth-child(2) {
                margin-right: 0;
            }
        }
    }
}

.game-input {
    border: 1px solid white;
    position: relative;
    .checkmark {
        display: none;
    }
    .warning {
        display: none;
    }
    &.correct {
        border: 1px solid #00873D;
        color: #00873D;
        .checkmark {
            display: block;
        }
        .warning {
            display: none;
        }
    }
    &.wrong {
        border: 1px solid #AA236A;
        color: #AA236A;
        .checkmark {
            display: none;
        }
        .warning {
            display: block;
        }
    }
    .checkmark, .warning {
        position: absolute;
        right: 15px;
        top: 15px;
    }  
    .checkmark {
        top: 20px;
    }
}

.game-carpet {
    width: 100%;
}
.carpet-subtitle {
    position: absolute;
    bottom: 15px;
    text-align: center;
    width: 100%;        
}
.carpet {
    margin-top: 10px;
    position: absolute;
    transform-origin: top left;
    .carpet-img {
        border: 5px solid white;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.6);
        height: auto;
        max-width: none;
        img {
            height: auto;
            max-width: none;
        }
    }
    .carpet-element {
        position: absolute;
        opacity: 0;
        transition: all 0.5s ease-in;
        &.active {
            opacity: 1;
        }
    }
    .asperagus-element {
        position: absolute;
        opacity: 1;
        transition: all 0.5s ease-in;
        &.active {
            opacity: 0;
        }
    }
}

.key-audio {
    display: none;
}

#keys {
    position: fixed;
    left: 15px;
    bottom: 57px;
    height: 50px;
    line-height: 50px;
    background-color: white;
    border-radius: 10px;
    width: 70px;
    img {
        width: 40px;
        float: left;
        margin-top: 8px;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.keyboard {
    position: relative;
    top: 30px;
    .keyboard-instruction {
        position: relative;
        top: -20px;
        .play {
            display: none;
        }
    }
    .play-preview {
        width: 150px;
        position: fixed;
        bottom: 0px;
        right: 0px;
        display: none;
    }
    .keyboard-inner {
        transform-origin: top left;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 1080px;
        img {
            position: absolute;
            left: 0px;
            top: 0px;
        }
        .hotspot {
            position: absolute;
            left: 0px;
            top: 0px;
            height: 390px;
            width: 62px;
            // background-color: rgba(255,0,0,0.3);
            z-index: 2000;
            top: 109px;   
        }
        .key-container {
            &:nth-child(2),&:nth-child(4),&:nth-child(7),&:nth-child(9),&:nth-child(11),&:nth-child(14),&:nth-child(16) {
                .hotspot {
                    height: 260px;
                    width: 44px;   
                    // background-color: rgba(0,255,0,0.3);      
                }       
            }
        }        
        .hotspot-1 {
            left: 100px;
        }
        .hotspot-2 {
            left: 162px;
        }
        .hotspot-3 {
            left: 206px;
            width: 45px;
        }
        .hotspot-4 {
            left: 251px;
        }
        .hotspot-5 {
            left: 295px;
        }
        .hotspot-6 {
            left: 367px;
        }
        .hotspot-7 {
            left: 432px;
        }
        .hotspot-8 {
            left: 476px;
            width: 45px;
        }
        .hotspot-9 {
            left: 521px;
        }
        .hotspot-10 {
            left: 561px;
            width: 45px;            
        }
        .hotspot-11 {
            left: 606px;
        }
        .hotspot-12 {
            left: 650px;
        }
        .hotspot-13 {
            left: 722px;
        }
        .hotspot-14 {
            left: 784px;
        }
        .hotspot-15 {
            left: 828px;
            width: 45px;
        }
        .hotspot-16 {
            left: 873px;
        }
        .hotspot-17 {
            left: 917px;
        }        
    }
}

.catching {
    margin-top: 15px;
    position: absolute;
    transform-origin: top left;
    border: 5px solid white;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.6);
    overflow: hidden;
    img {
        pointer-events: none;
    }
    .catching-inner {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        overflow: visible;
    }
    .pot {
        position: absolute;
        top: -410px;
        z-index: 100;
        padding: 60px;
    }
    .catching-img {
        height: auto;
        max-width: none;
        img {
            height: auto;
            max-width: none;
            pointer-events: none;
        }
    }
}

.multiple-photos {
    transform-origin: top left;
}

.multiple-photo {
    padding: 0px 30px;
    width: auto;
    img {
        border: 3px solid white;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.6);
    }
}

.multiple-answer {
    height: 31px;
    line-height: 25px;
    background-color: black;
    color: white;
    text-align: center;
    margin-bottom: 15px;
    border: 3px solid black;
    &.active {
        // border: 3px solid #FFA0AB;
    }
    &.correct {
        border: 3px solid green;
    }
    &.wrong {
        border: 3px solid red;
    }
}

// .game-09-check-results {
//     background-color: #FFA0AB;
//     color: white;
//     height: 31px;
//     line-height: 31px;
//     width: 60px;
//     position: absolute;
//     right: 15px;
//     bottom: 15px;
//     text-align: center;
//     opacity: 0.5;
//     .icon {
//         width: 16px;
//         height: 16px;
//         margin: 0 auto;
//         position: relative;
//         top: 2px;
//     }
//     &.active {
//         opacity: 1;
//     }
// }

.case {
    margin-top: 30px;
    position: absolute;
    transform-origin: top left;
    .game10-start-animation {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 100;
        width: 100%;
        height: 100%;
        .case-top {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;  
            height: 100%; 
            img {
                width: 100%;  
            }               
        }
        .case-bottom {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;  
            height: 100%;  
            img {
                width: 100%;  
            }          
        }
    }
    .case-img {
        border: 5px solid white;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.6);
        height: auto;
        max-width: none;
        img {
            height: auto;
            max-width: none;
        }
    }
    .case-placeholder {
        width: 250px;
        height: 250px;
        position: absolute;
        padding: 10px;
    }
    .case-letters {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .case-letter {
        position: relative;
        width: 230px;
        height: 230px;
        display: inline-block;
        touch-action: none;
        transform: translate(0px, 0px);
        transition: background-color 0.3s;
        z-index: 10;
        &.correct {
            opacity: 0.5;
            z-index: 1;
        }
    }
}

.vertical-hand {
    width:80px;
    position:absolute;
    bottom:100px;
    left:50%;
    margin-left:-40px;
    animation:MoveUpDown 1s linear;
    animation-iteration-count: 1;
    opacity:1;
}

.vertical-hand img {
    width:100%;
    height:auto;
    animation:rotationHand 5s linear infinite;
    animation-delay: 1s;
    position: relative;
    z-index:5;
}

.small-image, .pling {
    text-align: center;
    img {
        margin: 0 auto;
        max-height: 200px;
        border: 3px solid white;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.7);
    }
    .pling-subtitle {
        display: block;
        max-width: 400px;
        margin: 0 auto;
    }
}

.explosion {
    position: absolute;
    top:5%;
    right:25%;
    opacity:0;
    width:10px;
    height:10px;
    border-radius: 50%;
    background-color: #FFA0AB;
    animation: explode 5s ease-in-out infinite;
    animation-delay: 1.5s;
    z-index:1;
}

@keyframes MoveUpDown {
  0%, 20% {
    opacity:0;
    bottom: 20px;
  }
  100% {
    opacity:1;
    bottom: 100px;
  }
}

@keyframes rotationHand {
    15% { transform:rotate3d(1.2, 1.5, 0.75, 60deg); }
    30% { transform:rotate3d(0,0,0,0deg); }
}

@keyframes explode {
    25% { 
        opacity:1;
        transform: scale(10); 
    }
    40% { 
        opacity:0;
        transform: scale(1); 
    }
}